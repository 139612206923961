$badgeWidth: 280px;
$teamsPurple: #6c6ed4;

.badge-main-container{
    padding:1rem;    
}
.badge-container{
height: 100%;
}
.badge-title-container{
    padding-bottom:0.5rem;
    .logo{
        height: 75px;
    }
    
}
.badge-person-name{
    font-size:1.3rem;
}
.badge-pic{
    z-index:0;
    border-radius:50%;
    width: 85px;
    height: 85px;
    overflow:hidden;
    border:3px solid rgba(255,255,255,0.5);
}
.badge-badge-container{
    min-width:410px;
}
.badge-badge{
    display: flex;
    justify-content: space-between;
    border-radius:12px;
    padding:5px;
    font-size:1.1rem;
    width: $badgeWidth;
    height: 170px;
    // cursor:pointer;
    margin:20px 0px 20px 0px;
    z-index:2;
}
.badge-badge-background{
    border:1px solid rgba(173, 173, 173, 0.5);
    background-color:rgb(247, 247, 247);
    -webkit-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.1); 
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.1);
}
.badge-badge-background-dark{
    border:1px solid rgba(173, 173, 173, 0.2);
    background-color:rgb(41, 45, 56);
    -webkit-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.3); 
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.3);
}
.badge-badge-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-basis:100%;
    width: 100%;
    padding:0px 12px 0px 12px;
}
.badge-badge-user-location{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align:right;
    font-size:0.8rem;
    opacity: 0.6;
}
.badge-badge-user-id
{
    text-align:right;
    font-size:0.8rem;
    opacity: 0.6;
}
.badge-badge-user-name{
    padding-top:10px;
    font-size:1rem;
}
.badge-badge-user-description{
    font-size:0.8rem;
}
.badge-badge-company-logo{
    width:18px;
}
.badge-badge-side{
    width:15px;
    background-color:rgba(212, 212, 212, 0.5);
    border-radius: 10px;
}
.badge-badge-side-active{
    background-color:rgb(93, 43, 209);
    border-radius: 5px;
    -moz-transition:all 0.5s ease-in-out;
    -webkit-transition:all 0.5s ease-in-out;
    -o-transition:all 0.5s ease-in-out;
    -ms-transition:all 0.5s ease-in-out;
    transition:all 0.5s ease-in-out;
    -moz-animation:blink normal 1.5s infinite ease-in-out;
    -webkit-animation:blink normal 1.5s infinite ease-in-out;
    -ms-animation:blink normal 1.5s infinite ease-in-out;
    animation:blink normal 1.5s infinite ease-in-out;
}

.badge-header-company-name{
    color: $teamsPurple;
}

.badge-action-description{
    width:100px;
    display:flex;
    flex-direction: column;
    opacity:0;
    font-size:0.8 rem;
    color:rgb(172, 172, 172);
}

.badge-action-description-left{
    position:absolute;
    z-index:0;
    margin-left:-180px;
    text-align:center;
    opacity:0;
}
.badge-action-description-right{
    position:absolute;
    margin-left:180px;
    z-index:0;
    text-align:center;
    opacity:0;
}

.badge-badge-text{
    font-size:0.9rem;
}
.badge-badge-time{
    color:rgb(93, 43, 209);
    font-size:1.5rem;
}

.badge-breadcrumb-container{
    max-width: 320px;
    width: 320px;
    min-height: 220px;
    height: 100%;
    border-right:1px solid rgba(175, 175, 175, 0.20);
}
.badge-breadcrumb-title{
    border-bottom:1px solid rgba(175, 175, 175, 0.20);
    padding-bottom: 8px;
    margin-bottom:15px;
}
.badge-breadcrumb-day{
    display:flex;
    align-items:center;
    justify-content: center;
    border:1px solid rgb(204, 204, 204);
    font-size: 1.5rem;
    font-weight: 300;
    width:40px;
    height: 40px;
    text-align: center;
}
.badge-breadcrumb-day-name{
    font-weight: 300;
    font-size: 1.1rem;
}
.badge-timesheet-row{
    padding:3px 0px 3px 0px;
}
.badge-timesheet-row-time{
    font-weight: 500;
    margin-right: 5px;
    color: $teamsPurple;
    padding-left:20px;
}

.badge-history-no-actions{
    padding-top: 10px;
    text-align: center;
    font-size: 0.7rem;

    .badge-history-instructions{
        margin-top:10px;
        color:$teamsPurple;
        font-size: 0.85rem;
    }
}

.badge-history-workingmode{
    margin-right:3px;
    position: absolute;
}


.badge-action-selector-text {
    font-size:1.1rem;
}
.badge-action-text {
    margin-top:3px;
    /*font-size:1.1rem;*/
    font-weight: 600;
}
.badge-drop-down-actions
{
    display:flex;
    justify-content: center;
    align-items: center;
    min-width: $badgeWidth;
}

.badge-drop-down-actions-label{
    margin-top:4px;
    margin-right:5px;
}

.badge-working-mode-instructions{
    font-size:0.8rem;
    font-weight: 100;
    padding-top:10px;
}

span.ui-button__content{
    /*font-size:1.1rem !important;*/
}

div.ui-dropdown__item__header{
    /*font-size:1.1rem !important;*/
}

.ui-dropdown{
    margin-left: 20px;
}

.badge-workingmode-selector-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
}

@keyframes blink {
    0% {
           background-color: rgba(93, 43, 209,1)
    }
    50% {
           background-color: rgba(93, 43, 209,0.35)
    }
    100% {
           background-color: rgba(93, 43, 209,1)
    }
}

@-webkit-keyframes blink {
    0% {
        background-color: rgba(93, 43, 209,1)
    }
    50% {
            background-color: rgba(93, 43, 209,0.35)
    }
    100% {
            background-color: rgb(54, 35, 97)
    }
}

