.assistances-titles{
    font-size:1.3rem;
    padding-left:7px;
}

.assistances-sub-titles{
    font-size:1.0rem;
    padding-left:7px;
}


.assistances-main-container{

    padding:20px;
    height: 100%;
    width: 100%;

    .assistances-container{
        width:100%;

        .assistances-selector-container{
            width:100%;
            border-bottom:1px solid rgba(204, 204, 204, 0.6);
            align-items: center;
            justify-content: space-between;
            height:51px;            
        }
        .assistances-period-selector{
            width: 100%;
            min-width:170px;
            height:51px;
        }
    }

    .assistances-table{
        width: 100%;
        padding-left: 20px;
    }

    .assistances-norows{
        width: 100%;
        text-align: center;
        padding: 20px;
    }
}