.custom-period-container{

    .period-dd{
        margin-top: 3px;
        width: 272px;
    }

    @media screen and (max-width: 800px) { 
        .period2-dd{
            padding-top: 10px;
        }
    }
padding-left: 0px !important;
    // min-width:240px;
    // max-width:340px;
    // padding:5px;
    // margin:0px;
}