.overtimeviewer-container{

    padding:20px;
    
    .table{
        width: 100%;
        // padding-left: 20px;
    }

    .table-header{
        font-weight: bold;   
       
    }
}