.staff-main-list{
    width: 50%;
}


.staff-main-container{


    .staff-filter-field{

        min-width:220px;
        max-width:320px;
        padding:5px;
        margin:0px;
    }
    .staff-button{
        margin-top: 5px;;
    }

    .staff-row-default:hover{
        background-color: rgb(237, 235, 233);
    }

    .staff-row-dark:hover{
        background-color: rgb(59, 58, 57);
    }

    .staff-users-container{
        // width:50%;
        height: 900px;        
    }

    

}