$teamsPurple: #6c6ed4;
h1{
    color: $teamsPurple;
}
.loader-padding{
    padding:50px 0px 50px 0px;
}
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
  }
.app-notifications-container{
    position: absolute;
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    padding-top:5px;
    z-index: 50000000;

}

.app-notification{
    position: relative;
    margin:0 auto;
}

.half-opacity{
    opacity:0.5
}