$teamsPurple: #6c6ed4;
$teamsPurpleTransparent: #6c6ed48f;
  .assistances-titles{
    font-size:1.3rem;
    padding-left:7px;
  }

  // .ui-dropdown{
  //   margin-left: 0px !important;
  // }

  .assistences-form .ui-dropdown{
    margin-left: 0px !important;
  }

  .assistances-flex{
    width:235px;
  }

  .tab{
    //min-height:50px;
    width:100%;
    padding:25px;
    font-family:sans-serif;
    color:#444;
  }

  .assistences-form{
    padding-left: 20px !important;
  }
  
  ul.inline{
    list-style:none;    
    padding: 0;
    margin-bottom:0;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 0px;
    li{
      display:inline-block;
      margin-left:0;
      padding-top : 0.5rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      cursor:pointer;
    //   &.selected{
    //     border-bottom:3px solid #9ea2ff;
    //   }
    //   &:hover{
    //     border-bottom:3px solid red;
    //   }
    }
    div{
       
        height: 40px;
        &.selected{
            border-bottom:3px solid #9ea2ff !important;
          }
          &:hover{
              border-bottom:3px solid grey;
          }    
    }    
  }