.user-selector-main{
    width: 472px;
}

.user-selector-container{
    .selector-dd{
        margin-top: 3px;
        width: 272px;
    }

    @media screen and (max-width: 800px) { 
        .period2-dd{
            padding-top: 10px;
        }
    }
    padding-left: 0px !important;    
}