.calendar-container{

    padding:20px;
    
    .calendar-table{
        width: 100%;
        padding-left: 20px;
    }

    .data-column{
        width: 5%;
    }

    .detail-column{
        width: 95%;
        //min-height: 74px;
    }

    .action-container{
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-left-style:solid;
        border-right-style: solid;
        border-top-style: solid;
        border-bottom-style: solid;
        box-sizing: border-box;
        border: 1px;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 12px;
        padding-right: 12px;
        height: 48px;
        margin-top: 4px;
        //margin-bottom: 3px;
    }

    .action-container-default{
        background-color: rgb(250, 249, 248);
        color: rgb(37, 36, 35);
    }
    .action-container-default:hover{
        background-color: rgb(237, 235, 233);
    }

    .action-container-dark{
        color: rgb(255, 255, 255);
        background-color: rgb(41, 40, 40);
    }
    .action-container-dark:hover{
        background-color: rgb(59, 58, 57);
    }
    
}