.monthlyActivity-container{
    .action-details{
        margin-right: 0px !important;
    }
    .action-container{
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-left-style:solid !important;
        border-right-style: solid !important;
        border-top-style: solid  !important;
        border-bottom-style: solid !important;
        box-sizing: border-box; 
        border: 1px;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 12px;
        padding-right: 12px;
        // height: 48px;
        margin-top: 3px;
        margin-bottom: 3px;
    }

    .action-container-default{
        background-color: rgb(243, 242, 241);
        color: rgb(37, 36, 35);
    }
    .action-container-default:hover{
        background-color: rgb(237, 235, 233);
    }

    .action-container-dark{
        color: rgb(255, 255, 255);
        background-color: rgb(41, 40, 40);
    }
    .action-container-dark:hover{
        background-color: rgb(59, 58, 57);
    }

    .action-details-main-box{
        width: 100% !important;
        padding-left: 4px !important;
        padding-right: 4px !important;
    }

    .action-detail-workingmode-icon{
        position: absolute;
        margin: 0 auto;
        left:0 ;
        // right: 0;
    }

    .action-detail-btn-gestione{
        position: absolute;
        right: 0;
    }
    .action-detail-container1{
        display: inline-grid;
    }
    .action-detail-container2{
        width: 90%;
        overflow-x: auto;            
    }

    .action-detail-container3{
        width: 100%;
        overflow-x: auto;            
    }
    
    .action-detail-container2::-webkit-scrollbar {
        height: 10px !important;
    }

    .action-time-element{
        z-index: 10;
    }
    

    @media screen and (max-width: 1600px) {
        .action-time {
          display: none;
        }
      }
}