.time-selector-main{
    padding: 5px;
    height: 100px;
    vertical-align: middle;

    .text-input{
        width:65px;
    }

    .text-divider{
        padding-top: 20px;
    }       
}

.time-selector-compact{
    height: auto !important;
}

.time-selector-error{
    padding-top: 25px;
    color:rgb(247, 94, 117);
}