$teamsPurple: #6c6ed4;
$teamsPurpleTransparent: #6c6ed48f;


.monthly-team-navigator-loader{
    position:absolute;
    margin-left:-30px;
    margin-top:-2px;
}

.monthly-titles{
    font-size:1.3rem;
    padding-left:7px;
}

.person-name{
    cursor: pointer;
}

.person-name:hover{
    color:$teamsPurple;
}


.monthly-main-container{

    padding:20px;    

    .monthly-month-container{
        width:100%;

        .monthly-month-selector-container{
            width:100%;
            border-bottom:1px solid rgba(204, 204, 204, 0.6);
            align-items: center;
            justify-content: space-between;
            height:51px;

            .monthly-month-selector{
                align-items: center;
                min-width:170px;
                height:51px;
            }
        }
    }

    .monthly-team-navigator{
        min-width:220px;
        
        
        .monthly-team-navigator-header{
            padding:5px 5px 5px 5px;
            height:50px;
            align-items: center;

            .monthly-team-navigator-back{
                cursor: pointer;
                padding-top:5px;
            }

            .monthly-team-navigator-back:hover{
                color:$teamsPurple;
            }

            .monthly-team-navigator-title{
                font-weight: 600;
                margin-left:5px;
            }
        }

        .monthly-team-navigator-list{
            
            border-top:1px solid rgba(204, 204, 204, 0.6);
            height: 840px;
            overflow-y: auto;
            overflow-x: hidden;

            
            .monthly-team-navigator-list-item{
                min-height:33px;
                margin-left:5px;
                padding:2px 0px 2px 20px;
                border-bottom:1px solid rgba(204, 204, 204, 0.6);
                justify-content: space-between;
                align-items: center;
                font-size:0.8rem;
                padding-bottom:5px;
                .monthly-team-size{
                    color:$teamsPurple;
                    padding-top:3px;
                }

                .team-size-indicator{
                    display: flex;
                    border-radius:1px;
                    justify-content: center;
                    align-items: center;
                    font-weight: 600;
                    height: 25px;
                    min-width: 25px;
                    cursor:pointer;

                    .team-size-arrow{
                        margin-top:5px;
                        margin-left:5px;
                    }
                }
                .team-size-indicator:hover{
                    color: $teamsPurple;
                }
    
            }

        }
    }
    .ticket-restaurant{
        display:flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color:rgb(70, 70, 255);
        border-radius:2px;
        padding:2px;
        color:rgb(255, 255, 255);
        font-size:0.7rem;
        height:15px;
        width:20px;
        margin-top:1px;
    }
}


.monthly-days-list{

    // width: 100px;
    // min-width: 100px;
    
    .monthly-day-ok-icon{
        color:rgb(22, 187, 22);
    }

    .monthly-day-ko-icon{
        color:rgb(255, 85, 122);
    }

    .monthly-days-day{

        border-right:1px solid rgba(204, 204, 204, 0.6);
        padding:4px 8px 4px 4px;
        font-size:0.8rem;
        font-weight: 100;
        -webkit-box-shadow: inset -5px 0px 3px -4px rgba(0, 0, 0, 0.548); 
        box-shadow: inset -5px 0px 3px -4px rgba(0,0,0,0.548);
        display: flex;
        justify-content: space-between;

        .monthly-days-day-name{
            margin-left:8px;
            cursor:pointer;
        }

        .monthly-days-day-name-noselect{
            margin-left:8px;
        }

        .monthly-days-day-name:hover{
            color:$teamsPurple !important;
        }

        .monthly-days-day-name-festivity{
            color:rgb(28, 168, 248) !important;
        }

        .monthly-days-day-icon{
            margin-top:1px;
            
        }
    }

    .monthly-days-day-selected{
        border-right:none !important;
        border-top:1px solid rgba(204, 204, 204, 0.6);
        border-bottom:1px solid rgba(204, 204, 204, 0.6);
        border-left:1px solid rgba(204, 204, 204, 0.6);
        -webkit-box-shadow: -5px 0px 3px -4px rgba(0,0,0,0.548); 
        box-shadow: -5px 0px 3px -4px rgba(0,0,0,0.548);
    }
    .monthly-days-day-selected-first{
        border-right:none !important;
        border-bottom:1px solid rgba(204, 204, 204, 0.6);
        border-left:1px solid rgba(204, 204, 204, 0.6);
        -webkit-box-shadow: -5px 0px 3px -4px rgba(0,0,0,0.548); 
        box-shadow: -5px 0px 3px -4px rgba(0,0,0,0.548);
    }
    .monthly-days-day-selected-last{
        border-right:none !important;
        border-top:1px solid rgba(204, 204, 204, 0.6);
        border-left:1px solid rgba(204, 204, 204, 0.6);
        -webkit-box-shadow: -5px 0px 3px -4px rgba(0,0,0,0.82); 
        box-shadow: -5px 0px 3px -4px rgba(0,0,0,0.82);
    }
}


.monthly-day-content{
    width:100%;
}

.monthly-timesheet-container{

    padding-top:15px;
    padding-left:20px;
    width: 100%;

    .monthly-timesheet-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .monthly-workexpected{
        margin-right:30px;
        background-color: rgba(204, 204, 204, 0.4);
        padding:2px 5px 2px 5px;
    }

    .monthly-actions-title{
        font-size:1.2rem;
        font-weight:100;
        padding-bottom:10px;
    }

    .monthly-timesheet-row{
        padding:3px 0px 3px 0px;
    }
    .monthly-timesheet-row-time{
        font-weight: 500;
        margin-right: 5px;
        color: dodgerblue;
        padding-left:20px;
    }
    
    .monthly-timesheet-no-actions{
        padding-top: 10px;
        text-align: left;
        font-size: 0.7rem;
    }
    
    .monthly-timesheet-workingmode{
        margin-right:3px;
        position: absolute;
    }

    .monthly-timesheet-addAction{
        padding-top: 10px;       
    }
}



.monthly-holidays{
    padding-right:15px;
    display:flex;
    align-items: center;

    .monthly-num-holiday{
        cursor: pointer;
        color:$teamsPurple;
        margin-left:5px;
        margin-right:2px;
    }
    
    .monthly-num-holiday:hover{
        color:$teamsPurpleTransparent;
    }

    
    
}

.monthly-used-holiday-details{

    .monthly-used-holiday-list{

        font-size:0.75rem;
        margin-top:10px;
        max-height: 200px;
        overflow-y: auto;
        overflow-x: hidden;
        .monthly-used-holiday-row{
            font-size:0.75rem;
            padding-top:3px;
            padding-bottom:3px;
            border-top: 1px solid rgba(173, 173, 173, 0.5);
            padding-right:5px;
        }
    }

    .monthly-used-holiday-title{
        font-weight: 600;
        margin-top:15px;
        
    }
}

.monthly-holiday-gg{
    margin-left:2px;
    font-size:0.7rem;
}

.monthly-anomalies-container{

    padding-top:15px;
    padding-left:20px;
    width: 100%;

    .anomaly-text{
        color:rgb(255, 108, 108);
        border-top:1px solid rgba(173, 173, 173, 0.5);
        padding-top:5px;
        padding-bottom:5px;
        width: 100%;
    }

    .monthly-anomalies-title{
        font-size:1.2rem;
        font-weight:100;
        padding-bottom:10px;
    }
}


.monthly-assistances-container{

    padding-top:15px;
    padding-left:20px;
    width: 100%;

    .monthly-assistances-title{
        font-size:1.2rem;
        font-weight:100;
        padding-bottom:10px;
    }
    .assistance-row{
        border-top:1px solid rgba(173, 173, 173, 0.5);
        padding-top:5px;
        padding-bottom:5px;
        width: 100%;

        .assistance-delete-icon{
            color:rgb(255, 108, 108);
            margin-top:2px;
            margin-left:5px;
            cursor:pointer;
        }
        .assistance-delete-icon:hover{
            color:rgba(255, 108, 108, 0.61);
        }
        .assistance-approve-icon{
            color:rgb(51, 233, 51);
            margin-top:2px;
            margin-left:5px;
            cursor:pointer;
        }
        .assistance-approve-icon:hover{
            color:rgba(51, 233, 51, 0.61);
        }
    }
}

.monthly-overtime-container{

    padding-top:15px;
    width: 100%;

    .monthly-overtime-title{
        font-size:1.2rem;
        font-weight:100;
        padding-bottom:10px;
    }

    .monthly-overtime-earned{
        
        align-items: flex-end;
       

        .monthly-overtime-earned-amount{
             color:dodgerblue;
             font-size:1.1rem;
        }
        .monthly-overtime-earned-description{
            font-size:0.75rem;
            margin-left:10px;
            padding-bottom:2px;
        }
    }

    .monthly-overtime-controls{

        margin-top:10px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width:100%;

        .monthly-overtime-account{

            padding-top:10px;
            width:100%; 
            justify-content: space-between;

            .monthly-overtime-amount-approved{
                font-weight:600;
                font-size:1.3rem;
            }

            .monthly-overtime-account-title{
                font-weight:600;
            }
        }
        .monthly-overtime-account+.monthly-overtime-account{
           margin-left:10px;
           padding:10px;
           border-left:1px solid rgba(173, 173, 173, 0.5);
        }
    }
}