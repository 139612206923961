.ui-table__cell__content{
     width:100% !important;    
}

.ui-table__row{
    min-height: 45px !important;
}
.newmonthly-main-container{
    .ar{
        // height: auto !important;
    }

    .newmonthly-titles{
        min-width: 550px;
        font-size:1.3rem;
        padding-left:7px;
    }
    .newmonthly-table-header{
        font-weight: bold;   
        text-align: center;
    }
    .stickyCol{
        position: sticky;
        top : 0;
    }
    .stickyHeaderContainer{
        // 7px sono lo spazio occupato dall barra di scorrimento della tabella subito sotto
        padding-right: 7px;
        height: auto !important;
    }

    .otherMain{
        overflow-x: auto;
    }::-webkit-scrollbar {
        height: 10px !important;
    }
}